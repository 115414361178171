<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->


<div class="loginWrapp  items-center flex flex-row">
  <div class="mocaLogo justify-items-center w-[55%] order-2">
    <img src="assets/images/logo_white.jpg" alt="Moca" title="MOCA" />
  </div>
  <div class="loginInfo w-[45%] flex flex-col  self-stretch justify-center">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <h1>Log In</h1>
      <div class="mandatory flex flex-col">
        <mat-form-field>
          <mat-label>User Name</mat-label>
          <input type="text" name="username" matInput formControlName="Username" autocomplete="off" />
        </mat-form-field>
        <mat-error *ngIf="submitted && loginForm.hasError('required',['Username'])" class="invalid-feedback">
          User Name is required
        </mat-error>
        <mat-error  *ngIf="loginForm.hasError('alphanumeric',['Username'])">
          Please provide valid input.
        </mat-error>
      </div>
      <div class="mandatory flex flex-col">
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input [type]="hide ? 'text' : 'password'" name="password" matInput
            formControlName="Password" autocomplete="off" />
          <mat-icon matSuffix class="eyeball" (click)="hide = !hide">{{!hide ? 'visibility_off' : 'visibility'}}
          </mat-icon>
        </mat-form-field>
        <mat-error *ngIf="submitted && loginForm.hasError('required',['Password'])" class="invalid-feedback">
          Password is required
        </mat-error>
      </div>
      <div class="mandatory flex flex-col" *ngIf="(captcha$ | async)?.key; let key">
        <ngx-turnstile class="w-full" *ngIf="!!key?.length" [siteKey]="key" (resolved)="resolvedCaptchaToken = $event" theme="auto" [tabIndex]="0"></ngx-turnstile>
      </div>
      <div class="loginBtn flex flex-col">
        <button mat-raised-button color="primary" style="font-weight: 600;" [disabled]="!resolvedCaptchaToken">Log In</button>
        <div class="forgotPassword  flex flex-row">
          <a class="underline" routerLink='/forgot-password'>Forgot password?</a>
        </div>
      </div>
    </form>
  </div>
  <div class="appversion">Version {{version}}</div>
  <div class="appversion -mb-7">
    <span class="text-[11px]">Copyright © 2025 MOCA Financial Inc. | All Right Reserved.</span>
  </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.3)" size = "medium" color = "#fff" type = "ball-beat" [fullScreen] = "true"><p style="color: white" > Please wait... </p></ngx-spinner>
